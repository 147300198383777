import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Pagination,
  Chip,
} from "@mui/material";
import ProfileImg2 from "../../assets/images/profile2.svg";
import { axiosInstance } from "../../network/config/adapter";
import { ApiEndPoints } from "../../network/endpoints";
import { MEDIA_URL } from "../../network/endpoints";
import moment from "moment";
import Translations from "../../@core/layouts/components/Translations";
import FilterIcon from "../../assets/images/rotate-left.png";
import FilterPaymentDialog from "./filterPaymentDialog";

function PaymentCards() {
  const [myPaymentData, setMyPaymentData] = useState([]);
  const [isFilterPaymentDialogOpen, setIsFilterPaymentDialogOpen] =
    useState(false);
  const [allPaymentData, setAllPaymentData] = useState([]);
  const [pagination, setPagination] = useState({ total_pages: 1 }); // Default pagination state
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, selectedPage) => {
    setPage(selectedPage);
  };

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => Math.min(prevPage + 1, pagination.total_pages));
  };

  function getPaymentData(selectedPage) {
    axiosInstance
      .get(ApiEndPoints.PAYMENT.get_my_payment(selectedPage, limit), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setMyPaymentData(response.data.data);
        setAllPaymentData(response.data.data);
        setPagination(response.data.pagination);
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  }

  useEffect(() => {
    getPaymentData(page);
  }, [page]);

  function toggleFilterPaymentDialog() {
    setIsFilterPaymentDialogOpen((prev) => !prev);
  }

  function filteredPaymentList(filteredPaymentList) {
    setMyPaymentData(filteredPaymentList);
    // Recalculate pagination count based on the filtered data length
    const totalPages = Math.ceil(filteredPaymentList.length / limit);
    setPagination({ total_pages: totalPages });
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          <Translations text="paymentList.Payment" />
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <Button
            sx={{
              padding: "4px 8px",
              borderRadius: "40px",
              border: "1px solid #98A2B3",
              color: "#475467",
              textTransform: "none",
            }}
            onClick={() => setMyPaymentData([...allPaymentData])}
          >
            <Translations text="paymentList.Clear filter" />
          </Button>
          <Button
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "6px",
              boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px",
              width: "34px",
              height: "34px",
            }}
            onClick={toggleFilterPaymentDialog}
          >
            <img src={FilterIcon} alt="filter" />
          </Button>
        </Box>
      </Box>
      {myPaymentData?.map((item, index) => (
        <Card
          key={index}
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
            mt: "10px",
            paddingInline: "10px",
            borderRadius: "8px",
          }}
          elevation={0}
        >
          <CardContent sx={{ padding: "16px !important" }}>
            <Grid container>
              <Grid
                item
                xs={12}
                md={10}
                display={"flex"}
                alignItems={"center"}
                gap={"15px"}
                width={"100%"}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={`${MEDIA_URL}${
                      item?.caregiver_profile_image || ProfileImg2
                    }`}
                    alt="profile"
                    style={{
                      width: "64px",
                      height: "64px",
                      borderRadius: "14px",
                      border: "1.5px solid #FC9155",
                    }}
                  />
                </Box>
                <Box
                  width={"100%"}
                  display="flex"
                  flexDirection={"column"}
                  gap="5px"
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      width: { xs: "70%", sm: "80%", md: "90%" },
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      "@media (max-width: 600px)": {
                        fontSize: "16px",
                      },
                    }}
                  >
                    {item?.caregiver_full_name}
                  </Typography>
                  {item?.caregiver_account_status === "deleted" && (
                    <Chip
                      label={"Inactive"}
                      color={"error"}
                      sx={{ width: "90px", textTransform: "capitalize" }}
                    />
                  )}
                  <Typography
                    sx={{
                      fontSize: "16px",
                      width: { xs: "70%", sm: "80%", md: "90%" },
                      wordBreak: "break-word",
                    }}
                  >
                    {moment.utc(item?.created_at).format("MMM DD, YYYY h:mm A")}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                display={"flex"}
                alignItems={"center"}
                sx={{
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "flex-end",
                  },
                }}
                xs={12}
                md={2}
              >
                <Box
                  sx={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    padding: "10px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography color={"#475467"} fontWeight={"bold"}>
                    ${item.total_cost}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <FilterPaymentDialog
        isOpen={isFilterPaymentDialogOpen}
        toggle={toggleFilterPaymentDialog}
        filteredPaymentList={filteredPaymentList}
      />
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center" }}>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={pagination.total_pages}
          page={page}
          onChange={handlePageChange}
          boundaryCount={2} // Show "Previous" and "Next" buttons
          showFirstButton
          showLastButton
          // Add event handlers for "Previous" and "Next" buttons
          onPagePrev={handlePreviousPage}
          onPageNext={handleNextPage}
        />
      </Box>
    </>
  );
}

export default PaymentCards;
