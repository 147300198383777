import React, { Suspense, useState, createContext } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import FallbackSpinner from "./@core/components/spinner";
import PaymentsPage from "./pages/payments";
import AuthGuard from "./@core/components/auth/AuthGuard";
// import PreviousBooking from "./pages/appointment/previousBooking";
// import Profile from "./pages/user profile";
import PatientInfo from "./pages/patient info";
import ServiceNeed from "./pages/service need";
import Language from "./pages/select language";

export const UserContext = createContext();

const Login = React.lazy(() => import("./pages/login/index"));
const Register = React.lazy(() => import("./pages/register"));
const WhoNeedCareComponent = React.lazy(() =>
  import("./pages/register/whoNeedCareComponent")
);
const ForgotPassword = React.lazy(() => import("./pages/login/forgotPassword"));
const ResetPassword = React.lazy(() => import("./pages/login/resetPassword"));
const OtpComponent = React.lazy(() => import("./pages/login/otpComponent"));
const PatientDetails = React.lazy(() =>
  import("./components/forms/patientDetailForm")
);
const MemberPatientDetails = React.lazy(() =>
  import("./components/forms/memberDetailForm")
);
const Home = React.lazy(() => import("./pages/home"));
const CaregiverProfile = React.lazy(() => import("./pages/caregiver profile"));
const BlankLayout = React.lazy(() => import("./layouts/BlankLayout"));
const Appointment = React.lazy(() => import("./pages/appointment"));
const CurrentBooking = React.lazy(() =>
  import("./pages/appointment/currentBooking")
);
const DeleteAccount = React.lazy(() =>
  import("./components/user profile/deleteAccount/deleteAccountForm")
);
const PreviousBooking = React.lazy(() => import("./pages/appointment/previousBooking"));
const Profile = React.lazy(() => import("./pages/user profile"));
const Success = React.lazy(() => import("./pages/payment status/success"));
const Reject = React.lazy(() => import("./pages/payment status/reject"));
const PrivacyPolicy = React.lazy(() => import("./pages/privacyPolicy"));
const TermsAndConditions = React.lazy(() => import("./pages/termsAndConditions"));

function App() {
  return (
    <div>
      <Suspense fallback={<FallbackSpinner />}>
        <AuthGuard>
          <Routes>
            <Route
              element={
                <BlankLayout>
                  <Outlet />
                </BlankLayout>
              }
            >
              <Route path="/profile" element={<Profile />} />
              <Route path="/caregiver-profile/:caregiverId" element={<CaregiverProfile />} />
              <Route path="/home" element={<Home />} />
              <Route path="/appointments" element={<Appointment />} />
              <Route
                exact
                path="/appointments/previous-booking/:bookingId"
                element={<PreviousBooking />}
              />
              <Route path="/payments" element={<PaymentsPage />} />
              <Route
                path="/appointments/current-booking/:bookingId"
                element={<CurrentBooking />}
              />
              <Route path="/delete-account" element={<DeleteAccount />}></Route>
              <Route path="/success" element={<Success />}></Route>
              <Route path="/reject" element={<Reject />}></Route>
            </Route>

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/choose-patient" element={<WhoNeedCareComponent />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/otp-verification" element={<OtpComponent />} />
            <Route path="/patient-details" element={<PatientDetails />} />
            <Route path="/patient-info/:patientName" element={<PatientInfo />} />
            <Route path="/service-need" element={<ServiceNeed />} />
            <Route path="/language" element={<Language />} />
            
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />

            <Route path="/404" element={<div>404</div>} />

            <Route
              path="/member-patient-details"
              element={<MemberPatientDetails />}
            />

            {/* <Route path="/" element={<Navigate to="/home" replace />} />
          <Route path="*" element={<Navigate to="/404" replace />} /> */}
          </Routes>
        </AuthGuard>
      </Suspense>
    </div>
  );
}

export default App;
