// ** React Imports
import { useEffect } from "react";

// ** Next Imports
import { useNavigate, useLocation } from "react-router-dom";

// ** Hooks Import
import { useAuth } from "../../../hooks/useAuth";

import { Routes } from "../../../constant/routes";

const GuestPath = [
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
  "/otp-verification",
  "/choose-patient",
  "/member-patient-details",
  "/patient-details",
  "/patient-info/self",
  "/patient-info/child",
  "/patient-info/partner",
  "/patient-info/client",
  "/patient-info/other",
  "/service-need",
  "/language",
  // "/success",
  // "/reject",
  "/privacy-policy",
  "/terms",
];

// const AuthGuard = props => {
//   const { children, fallback } = props
//   const auth = useAuth()

//   const navigate = useNavigate()
//   const location = useLocation()

//   useEffect(
//     () => {
//       const currentPath = location.pathname + location.search + location.hash
//       if (auth.user === null && !window.localStorage.getItem('userData')) {
//         if (!UnGuardedPaths.includes(location.pathname)) {
//           if (currentPath !== '/') {
//             navigate(`/?returnUrl=${currentPath}`)
//           } else {
//             navigate('/home')
//           }
//         }
//       }
//     },
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//     []
//   )

//   if (!UnGuardedPaths.includes(location.pathname) && (auth.loading || auth.user === null)) {
//     console.log('DEBUG here')
//     return fallback
//   }

//   return children
// }

// export default AuthGuard

const AuthGuard = (props) => {
  const { children, fallback } = props;
  const auth = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname + location.search + location.hash;
    // if (auth.user === null && !window.localStorage.getItem('userData')) {

    // If the user is not logged in
    if (auth.user === null) {
      // If the current path is not in the list of guest paths
      if (!GuestPath.includes(location.pathname)) {
        // Redirect the user to the login page with the original path as a query parameter
        navigate(`/login?redirect_url=${currentPath}`);
      }
    } else {
      // If the user is logged in
      // If the user is logged in and tries to access one of these routes
      if (
        location.pathname === "/login" ||
        location.pathname === "/" ||
        location.pathname === "/register" ||
        location.pathname === "/forgot-password" ||
        location.pathname === "/otp-verification" ||
        location.pathname === "/reset-password" ||
        location.pathname === "/language"
      ) {
        // Redirect the user to the home page
        navigate("/home");
      }
    }

    // console.log("auth.user", auth.user);
  }, [auth.user, location.hash, location.pathname, location.search, navigate]);

  if (
    !GuestPath.includes(location.pathname) &&
    (auth.loading || auth.user === null)
  ) {
    console.log("DEBUG here");
    return fallback;
  }
  return children;
};

export default AuthGuard;
