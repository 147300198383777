import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Container,
  Grid,
  Box,
} from "@mui/material";
import PaymentCards from "../../components/payments/paymentCards";

function PaymentsPage() {
  return (
    <Container sx={{ marginTop: "30px", paddingBottom: "24px" }}>
      <Card
        sx={{
          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          mt: "10px",
          paddingInline: "10px",
        }}
        elevation={0}
      >
        <CardContent>
            <PaymentCards/>
        </CardContent>
      </Card>
    </Container>
  );
}

export default PaymentsPage;
