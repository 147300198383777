import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TextField, InputAdornment } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";

import useCustomTranslation from '../@core/hooks/useCustomTranslation';

const DatepickerRangeFilter = ({ getDates, resetTrigger }) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const translation = useCustomTranslation();

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setEndDate(null);
    };

    useEffect(() => {
        if (startDate && endDate) {
            getDates(startDate, endDate);
        }
    }, [startDate, endDate]);

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    useEffect(() => {
        setStartDate(null);
        setEndDate(null);
    }, [resetTrigger])

    return (
        <>
            <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={null} // Allow selection of past dates
                dateFormat="dd-MM-yyyy"
                placeholderText={translation.translate("bookingFilterDialog.Start Date")}
                customInput={
                    <TextField
                        autoComplete="off"
                        fullWidth
                        variant="outlined"
                        placeholder="DD/MM/YYYY"
                        InputProps={{
                            style: {
                                border: "1px solid #475467",
                                borderRadius: "15px",
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarTodayOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
            <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={new Date()}
                minDate={startDate}
                dateFormat="dd-MM-yyyy"
                placeholderText={translation.translate("bookingFilterDialog.End Date")}
                showDisabledMonthNavigation // Allow navigation to past months
                customInput={
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="DD/MM/YYYY"
                        InputProps={{
                            style: {
                                border: "1px solid #475467",
                                borderRadius: "15px",
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarTodayOutlinedIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                }
            />
        </>
    );
};

export default DatepickerRangeFilter;
