import React, { useCallback, useContext, useEffect, useState } from "react";
import { BackgroundLayout } from "../../common/backgroundLayout";
import Box from "@mui/system/Box";
import { Typography, Container, Divider, Checkbox, FormControlLabel } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BlueButton, WhiteButton } from "../../common/button";
import { styled } from "@mui/system";
import { axiosInstance } from "../../network/config/adapter";
import { ApiEndPoints } from "../../network/endpoints";
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// Schema with checkboxes
const schema = yup.object({

});

// Create a custom-styled checkbox
const CustomCheckbox = styled(Checkbox)`
    color: #d0d5dd;
    &.Mui-checked {
      color: #fc9155;
    }
  `;

function ServiceNeed() {
    const navigate = useNavigate();

    const [needServiceList, setNeedServiceList] = useState([])

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const [selectedServices, setSelectedServices] = useState([]);

    const handleCheckboxChange = (service) => () => {
        const currentIndex = selectedServices.indexOf(service);
        const newSelectedServices = [...selectedServices];
        if (currentIndex === -1) {
            newSelectedServices.push(service);
        } else {
            newSelectedServices.splice(currentIndex, 1);
        }

        setSelectedServices(newSelectedServices);
    };

    const getNeedService = useCallback(() => {
        axiosInstance
            .get(`${ApiEndPoints.SERVICE_NEED.service_need}`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                console.log("response.data.data", response.data.data);
                setNeedServiceList(response.data.data);
            })
            .catch((error) => {
                console.error("API error:", error);
            });
    }, [])

    const onSubmit = async () => {
        let needServiceDetails = JSON.parse(localStorage.getItem("needServiceDetails"));

        const payloadForService = {
            services: selectedServices.join(', ')
        }
        const payload = {
            who_need_care: needServiceDetails.who_need_care,
            age: needServiceDetails.age,
            post_code: needServiceDetails.post_code,
            need_help: needServiceDetails.need_help,
        }

        try {
            const response = await axiosInstance.post(
                ApiEndPoints.PREFERENCES.add_preference,
                payload,
            );
            console.log(response.status);

            if (needServiceDetails.who_need_care === "self") {
                navigate("/patient-details")
            } else {
                navigate("/member-patient-details")
            }
            toast.success(response.data.message);

            if (response.status === 200) {
                localStorage.removeItem('needServiceDetails');
            }
            addCheckBoxData(payloadForService)
        } catch (error) {
            toast.error(error.response.data.message);
        }
    };

    const addCheckBoxData = (payloadForService) => {
        axiosInstance
            .patch(ApiEndPoints.SERVICE_NEED.update_service_need, payloadForService)
            .then((response) => {
                toast.success(response.data.message);
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            })
    }

    useEffect(() => {
        getNeedService()
    }, [getNeedService])

    const handleBack = () => {
        navigate(-1); // Go back to the previous page
      };
      const handleNavigateHome = () => {
        navigate('/home'); 
      };
    return (
        <div>
            <BackgroundLayout>
                <Container maxWidth="sm">
                    <Box sx={{ maxWidth: "100%" }}>
                        <Box sx={{ display: "flex", gap: 1, alignItems: "center",mb:2 }}>
                            <ArrowBackIosIcon sx={{ color: "#000000" }} onClick={handleBack} />
                            <Typography variant="h3" sx={{ color: "#000", fontSize: "36px", fontWeight: "bold" }}>
                                Services I need
                            </Typography>
                        </Box>

                        <Typography variant="h5" gutterBottom sx={{ color: "#667085", fontSize: "20px", fontWeight: "bold" }}>
                            Enter patient details
                        </Typography>

                        <Divider sx={{ borderColor: "#EAECF0", borderWidth: 1, marginY: 2 }} />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box mb={3} sx={{ display: "flex", flexDirection: "column" }}>
                                {
                                    needServiceList.map((service) => {
                                        return (
                                            <div style={{ display: "flex", alignItems: "center", }}>
                                                <CustomCheckbox
                                                    onChange={handleCheckboxChange(service.services)}
                                                    sx={{
                                                        "& .MuiSvgIcon-root": {
                                                            borderRadius: "20px",
                                                        },
                                                    }}
                                                />
                                                <Typography sx={{ color: "#344054", fontWeight: "500", textTransform: "capitalize" }}>{service.services}</Typography>
                                            </div>
                                        )
                                    })
                                }
                                {errors.checkboxes && (
                                    <Typography variant="body2" sx={{ color: "red" }}>
                                        {errors.checkboxes.message}
                                    </Typography>
                                )}
                            </Box>
                            <BlueButton
                                fullWidth
                                type="submit"
                                sx={{
                                    paddingInline: "40px !important",
                                    textTransform: "capitalize",
                                    fontSize: "14px !important",
                                    flexGrow: { xs: "1" },
                                }}
                            >
                                Next
                            </BlueButton> 
                            <WhiteButton
                                fullWidth
                                onClick={handleNavigateHome}
                                sx={{
                                    paddingInline: "40px !important",
                                    textTransform: "capitalize",
                                    fontSize: "14px !important",
                                    mt:2,
                                    flexGrow: { xs: "1" },
                                }}
                            >
                                Skip
                            </WhiteButton>
                        </form>
                    </Box>
                </Container>
            </BackgroundLayout>
        </div>
    );
}

export default ServiceNeed;
