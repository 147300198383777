import React from "react";
import Box from "@mui/system/Box";
import styled from "styled-components";
import backgroundImage from "../assets/images/Bg.svg";

export const BackgroundLayout = styled(Box)`
  min-height: 100vh;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 24px;
`;
