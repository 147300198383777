import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";

export const BlueButton = styled(Button)`
  border-radius: 100px !important;
  background-color: #024faa !important;
  padding: 12px 15px;
  color: #fff !important;
  font-weight: bold; !important;
  text-transform: capitalize !important;
`;

export const WhiteButton = styled(Button)`
  border-radius: 100px !important;
  background-color: #fff !important;
  padding: 12px 15px;
  color: #024FAA !important;
  font-weight: bold; !important;
  border: 1px solid #024FAA;
  text-transform: capitalize !important;
`;

export const FilterButton = styled(Button)`
  border-radius: 100px !important;
  background-color: none;
  padding: 12px 18px !important;
  font-weight: bold; !important;
  border: 1px solid #98A2B3 !important;
  color: #475467;
  text-transform: capitalize !important;
  font-weight: bold !important;
  margin-bottom: 8px !important;
  margin-right: 8px !important;
`;
