import React, { useState } from 'react'
import { Box, Grid, Paper, Radio, Typography, Divider, Button, InputLabel } from '@mui/material';
import styled from 'styled-components'
import { Link } from 'react-router-dom';
import { BackgroundLayout } from "../../common/backgroundLayout";
import Container from "@mui/material/Container";
import EiffelTower from '../../assets/images/Group 458.svg'
import Tomb from '../../assets/images/Group.svg'

const LogoBox = styled(Box)`
  padding-inline: 80px;
  padding-block: 55px;
`

const Language = () => {

    const [selectedLanguage, setSelectedLanguage] = useState('');

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);
    };

    const languageOptions = [
        // { value: 'english', label: 'English', imageUrl: EiffelTower, initialLetter: 'A' },
        { value: 'english', label: 'English', imageUrl: EiffelTower, initialLetter: 'A' },
        { value: 'malay', label: 'Malay', imageUrl: Tomb, initialLetter: 'M' },
        { value: 'thai', label: 'Thai', imageUrl: Tomb, initialLetter: 'แ' },
        { value: 'indonesian', label: 'Indonesian', imageUrl: Tomb, initialLetter: 'I' },
    ];

    return (
        <>


            <BackgroundLayout>
                <Container maxWidth="sm">
                    <Box sx={{ maxWidth: "100%" }}>
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={{ color: "#000", fontSize: "36px", fontWeight: "bold" }}
                        >
                            Select app language
                        </Typography>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ color: "#667085", fontSize: "20px", fontWeight: "bold" }}
                        >
                            Select language for you entire application
                        </Typography>

                        <Divider
                            sx={{ borderColor: "#EAECF0", borderWidth: 1, marginY: 2 }}
                        />

                        <form >
                            <Grid container spacing={1} justifyContent='center' paddingInline={"10%"}>
                                {languageOptions.map((option) => (
                                    <Grid key={option.value} item xs={6} md={6} display='flex' justifyContent='space-center'>
                                        <Paper
                                            sx={{
                                                position: 'relative',
                                                height: '150px', // Set the desired height for the tiles
                                                width: '100%', // Adjusted to 100% to fit in the grid container
                                                // backgroundImage: `url(${EiffelTower})`,
                                                backgroundColor: '#EA6D35',
                                                backgroundSize: 'cover',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                borderRadius: '16px',
                                                marginBottom: '30px',
                                            }}
                                            onClick={() => handleLanguageChange(option.value)}
                                        >
                                            <Radio
                                                checked={selectedLanguage === option.value}
                                                onChange={() => handleLanguageChange(option.value)}
                                                color="default"
                                                sx={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    right: 0,
                                                    color: "#D0D5DD",
                                                    "&.Mui-checked": {
                                                        color: "#FFF",
                                                    },
                                                }}
                                            />
                                            <Typography variant="h6" sx={{ color: '#fff', padding: '10px' }}>
                                                {option.label}
                                            </Typography>
                                            <Typography
                                                variant="h1"
                                                sx={{
                                                    fontSize: '72px',
                                                    position: 'absolute',
                                                    left: '50%', // Center horizontally
                                                    top: '50%', // Center vertically
                                                    transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
                                                    color: '#fff',
                                                    zIndex: 2,
                                                }}
                                            >
                                                {option.initialLetter}
                                            </Typography>
                                            {/* <img src={option.imageUrl} style={{
                                                fontSize: '72px',
                                                position: 'absolute',
                                                left: '50%', // Center horizontally
                                                top: '50%', // Center vertically
                                                transform: 'translate(-50%, -50%)', // Center both horizontally and vertically
                                            }} alt='language' /> */}
                                        </Paper>
                                    </Grid>
                                ))}
                            </Grid >
                        </form>
                    </Box>
                </Container>
            </BackgroundLayout>
        </>
    )
}

export default Language