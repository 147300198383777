import React, { useContext, useState } from "react";
import { BackgroundLayout } from "../../common/backgroundLayout";
import Box from "@mui/system/Box";
import { Typography, Container, InputLabel, Divider, TextField, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { BlueButton } from "../../common/button";
import { useParams, useNavigate } from 'react-router-dom';


const schema = yup.object({
    age: yup
        .string()
        .matches(/^\d{1,3}$/, "Invalid age")
        .required("Age is required"),
    postcode: yup
        .string()
        .matches(/^\d+$/, "Invalid postcode, must be a number")
        .required("Postcode is required"),
});

function PatientInfo() {
    const { patientName } = useParams();
    const navigate = useNavigate();
    const {
        reset,
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            age: "",
            postcode: "",
            needToHelp: "findProvider"
        },
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    const onSubmit = (data) => {
        console.log("data", data);

        const payload = {
            who_need_care: patientName,
            age: data?.age,
            post_code: data?.postcode,
            need_help: data?.needToHelp,
        }

        localStorage.setItem("needServiceDetails", JSON.stringify(payload));

        if (localStorage.getItem("needServiceDetails")) {
            navigate("/service-need");
        }

    };


    return (
        <div>
            <BackgroundLayout>
                <Container maxWidth="sm">
                    <Box sx={{ maxWidth: "100%" }}>
                        <Typography
                            variant="h3"
                            gutterBottom
                            sx={{ color: "#000", fontSize: "36px", fontWeight: "bold" }}
                        >
                            Information
                        </Typography>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ color: "#667085", fontSize: "20px", fontWeight: "bold" }}
                        >
                            Enter patient details
                        </Typography>

                        <Divider
                            sx={{ borderColor: "#EAECF0", borderWidth: 1, marginY: 2 }}
                        />

                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Box mb={3}>
                                <InputLabel sx={{ fontWeight: "900", marginBottom: "5px" }}>
                                    Age
                                </InputLabel>
                                <Box>
                                    <Controller
                                        name="age"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter Age"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.age}
                                                helperText={errors.age?.message}
                                                InputProps={{
                                                    style: {
                                                        border: "1px solid #475467",
                                                        borderRadius: "15px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>

                            <Box mb={3}>
                                <InputLabel sx={{ fontWeight: "900", marginBottom: "5px" }}>
                                    Postcode
                                </InputLabel>
                                <Box>
                                    <Controller
                                        name="postcode"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                placeholder="Enter Postcode"
                                                value={value}
                                                onChange={onChange}
                                                error={!!errors.postcode}
                                                helperText={errors.postcode?.message}
                                                InputProps={{
                                                    style: {
                                                        border: "1px solid #475467",
                                                        borderRadius: "15px",
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>

                            <Box mb={3}>
                                <InputLabel sx={{ fontWeight: "900", marginBottom: "5px" }}>
                                    Need help to
                                </InputLabel>
                                <Box sx={{ color: "#000" }}>
                                    <Controller
                                        name="needToHelp"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControl>
                                                <RadioGroup
                                                    column
                                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                                    name="row-radio-buttons-group"
                                                    value={value}
                                                    onChange={onChange}
                                                >
                                                    <FormControlLabel
                                                        value="find provider"
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: "#D0D5DD",
                                                                    "&.Mui-checked": {
                                                                        color: "#FC9155",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label="Find a provider"
                                                    />
                                                    <FormControlLabel
                                                        value="be ready for plan"
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: "#D0D5DD",
                                                                    "&.Mui-checked": {
                                                                        color: "#FC9155",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label="Be ready for a plan"
                                                    />
                                                    <FormControlLabel
                                                        value="apply for NDIS"
                                                        control={
                                                            <Radio
                                                                sx={{
                                                                    color: "#D0D5DD",
                                                                    "&.Mui-checked": {
                                                                        color: "#FC9155",
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        label="Apply for NDIS"
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    />
                                </Box>
                            </Box>
                            <BlueButton
                                fullWidth
                                type="submit"
                                sx={{
                                    paddingInline: "40px !important",
                                    textTransform: "capitalize",
                                    fontSize: "14px !important",
                                    flexGrow: { xs: "1" },
                                }}
                            >
                                Next
                            </BlueButton>
                        </form>
                    </Box>
                </Container>
            </BackgroundLayout>
        </div>
    )
}

export default PatientInfo