import axios from "axios";
import authConfig from "../../configs/auth";
import { toast } from "react-hot-toast";

export const axiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    authorization: `${localStorage.getItem(authConfig.storageTokenKeyName)}`,
  },
});

// You can set default headers like Content-Type here
axiosInstance.defaults.headers.common["Content-Type"] = "application/json";

// Add a request interceptor to set the authorization header
axiosInstance.interceptors.request.use(
  (config) => {
    // Get the token from localStorage and set the authorization header
    const token = localStorage.getItem(authConfig.storageTokenKeyName);
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add an interceptor to handle 401 responses - Invalid/Expired token
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log("Unauthorized. Redirecting to login page...");
    console.log("DEBUG error 123", error);
    console.log("DEBUG error 123", error.response.status);

    if (error.response.data.status_code === 1005) {
      window.localStorage.removeItem("userData");
      window.localStorage.removeItem(authConfig.storageTokenKeyName);
      toast.error("Session expired. Please log in again.", {
        position: "top-center",
        autoClose: 5000,
      });
      window.location.href = "/login";
      // window.location.reload();
    }
    return Promise.reject(error);
  }
);

// access token ->  8hr
// refresh token -> 90 days
