// ** React Imports
import { createContext, useEffect, useState } from "react";

// ** Next Import
import { useNavigate, useSearchParams, navigate } from "react-router-dom";

// ** Config
import authConfig from "../configs/auth";
import { axiosInstance } from "../network/config/adapter";
import { Routes } from "../constant/routes";

import FallbackSpinner from "../@core/components/spinner";
import { ApiEndPoints } from "../network/endpoints";
import { toast } from "react-hot-toast";
import axios from "axios";

// ** Defaults
const defaultProvider = {
  user: null,
  userName: null,
  userId: null,
  loading: true,
  resetToken: null,
  registerSuccessStatus: null,
  setRegisterSuccessStatus: () => Boolean,
  setUser: () => null,
  setLoading: () => Boolean,
  isInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setIsInitialized: () => Boolean,
  register: () => Promise.resolve(),
  otp: () => Promise.resolve(),
};
const AuthContext = createContext(defaultProvider);

const AuthProvider = ({ children }) => {
  // ** States
  const [userToken, setUserToken] = useState(
    window.localStorage.getItem(authConfig.storageTokenKeyName)
  );
  const [user, setUser] = useState(defaultProvider.user);
  const [userName, setUserName] = useState(defaultProvider.user);
  const [userId, setUserId] = useState(defaultProvider.userId);
  const [registerSuccessStatus, setRegisterSuccessStatus] = useState(
    defaultProvider.registerSuccessStatus
  );

  const [loading, setLoading] = useState(defaultProvider.loading);
  const [isInitialized, setIsInitialized] = useState(
    defaultProvider.isInitialized
  );

  // console.log("userId from context", userId);

  // console.log("userdata", user);
  // ** Hooks
  const navigate = useNavigate();
  const searchParams = useSearchParams();

  useEffect(() => {
    const initAuth = async () => {
      setIsInitialized(true);
      const storedToken = window.localStorage.getItem(
        authConfig.storageTokenKeyName
      );
      // console.log(storedToken);
      if (storedToken) {
        setLoading(true);
        await axiosInstance
          .get(ApiEndPoints.AUTH.me)
          .then((response) => response.data)
          .then(async (response) => {
            setLoading(false);
            console.log("DEBUG = ", response);
            setUser({ ...response.data });
          })
          .catch(() => {
            setLoading(false);
            handleLogout();
          });
      } else {
        setLoading(false);
      }
    };
    initAuth();
  }, []);

  // const handleLogin = async (params, errorCallback) => {
  //   console.log(params)
  //   await axiosInstance
  //     .post(ApiEndPoints.AUTH.login, params)
  //     .then(response => response.data)
  //     .then(async response => {
  //       console.log('response.data', response.data.data)
  //       navigate(Routes.DASHBOARD)
  //       setUser(response.data.data)
  //       localStorage.setItem(authConfig.storageTokenKeyName, response.token)
  //     })
  //     .catch(err => {
  //       if (errorCallback) errorCallback(err)
  //     })
  // }

  const handleLogin = async (params, errorCallback) => {
    let loginRole;
    console.log("params", params);
    sessionStorage.setItem("userEmail", params.email);
    await axiosInstance
      .post(ApiEndPoints.AUTH.login, params)
      .then((response) => response.data)
      .then(async (response) => {
        const setToken = response.token;
        loginRole = response.data.role;
        if (response.data.role === "caregiver") {
          return
        }
        if (setToken) {
          await localStorage.setItem(
            authConfig.storageTokenKeyName,
            response.token
          );
        }
      })
      .then(() => {
        if (loginRole === "caregiver") {
          toast.error("Your Account cannot access this platform.")
          return;
        }
        const token = localStorage.getItem(authConfig.storageTokenKeyName);
        console.log("token", token);
        axiosInstance
          .get(ApiEndPoints.AUTH.me, {
            headers: { Authorization: `${token}` },
          })
          .then((response) => response.data)
          .then(async (response) => {
            console.log("response", response);
            setUser({ ...response.data });

            // console.log("response username", response.data.data.name)
            await window.localStorage.setItem(
              "userData",
              JSON.stringify(response.data)
            );
            // if(response.success){
            navigate(Routes.ROUTES.HOME);
            // }
            // toast.success(response.data.message);
          });
      })

      .catch((error) => {
        console.log("error", error?.response?.data?.message);
        toast.error(error.response.data.message);
        if (error.response.data.message === "Please activate your account") {
          navigate("/otp-verification");
        }
        // if (errorCallback) errorCallback(err);
      });
  };
  const handleLogout = () => {
    setUser(null);
    setIsInitialized(false);
    setUser(null);
    window.localStorage.removeItem("userData");
    localStorage.removeItem(authConfig.storageTokenKeyName);
    navigate(Routes.ROUTES.LOGIN);
  };

  const handleRegister = ({ params }, errorCallback) => {
    console.log("params from register", params);
    axiosInstance
      .post(ApiEndPoints.USER_REGISTER.register, params)
      .then((res) => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error);
        } else {
          console.log("response.data", res.data.data);
          // setRegisterSuccessStatus(true);
          sessionStorage.setItem("userEmail", params.email);
          sessionStorage.setItem("otpToken", res.data.token);
          toast.success(res.data.message);
          navigate("/otp-verification");
        }
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);

        toast.error(err.response?.data?.message || "An error occurred");
      });
  };

  const handleOTP = ({ params }, errorCallback) => {
    console.log("params from OTP", params);

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("otpToken")}`,
    };

    axios
      .patch(ApiEndPoints.VERIFY_OTP.verify, params, { headers })
      .then((res) => {
        if (res.data.error) {
          if (errorCallback) errorCallback(res.data.error);
        } else {
          console.log("response.data", res.data.token);
          localStorage.setItem("userData", JSON.stringify(res.data.data));
          localStorage.setItem(authConfig.storageTokenKeyName, res.data.token);
          setRegisterSuccessStatus(false);
          toast.success(res.data.message);
          navigate("/choose-patient");
        }
      })
      .catch((err) => {
        if (errorCallback) errorCallback(err);

        console.log("error: ", err);
        toast.error(err.message);
      });
  };


  const values = {
    userToken,
    user,
    userName,
    userId,
    loading,
    registerSuccessStatus,
    setRegisterSuccessStatus,
    setUser,
    setLoading,
    isInitialized,
    setIsInitialized,
    login: handleLogin,
    logout: handleLogout,
    register: handleRegister,
    otp: handleOTP,
  };

  return (
    <AuthContext.Provider value={values}>
      {loading ? <FallbackSpinner /> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
