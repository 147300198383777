import i18n, { init } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next'

import enTranslation from '../locales//en/translation.json';
import idTranslation from '../locales/id/translation.json';
import msTranslation from '../locales/ms/translation.json';
import thTranslation from '../locales/th/translation.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },

        resources: {
            en: {
                translation: enTranslation
            },
            id: {
                translation: idTranslation
            },
            ms: {
                translation: msTranslation
            },
            th: {
                translation: thTranslation
            }
        }
    })
