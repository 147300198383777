export const API_BASE_URL = "https://dev.caredac.com/api/v1";

// Development
// export const MEDIA_URL = "https://d28qzefaa2n2nh.cloudfront.net";

// Production
export const MEDIA_URL = "https://dlhgrltgo56b6.cloudfront.net";

export const ApiEndPoints = {
  AUTH: {
    login: `${API_BASE_URL}/auth/login`,
    me: `${API_BASE_URL}/common/profile`,
  },
  USER_REGISTER: {
    register: `${API_BASE_URL}/auth/register`,
  },
  CHANGE_PASSWORD: {
    change_password: `${API_BASE_URL}/auth/change-password`,
  },
  VERIFY_OTP: {
    verify: `${API_BASE_URL}/auth/verify-account`,
  },
  SEND_OTP: {
    send: `${API_BASE_URL}/auth/resend-otp`,
  },
  FORGOT_PASSWORD: {
    forgot_password: `${API_BASE_URL}/auth/forgot-password-link`,
    reset_password: (token) =>
      `${API_BASE_URL}/auth/reset-password-link?token=${token}`,
  },
  PATIENT: {
    add_details: `${API_BASE_URL}/common/profile`,
    edit_details: `${API_BASE_URL}/common/edit-profile`,
    get_details: `${API_BASE_URL}/common/profile`,
    delete_account: `${API_BASE_URL}/auth/account`,
  },
  MEMBER: {
    add_member: `${API_BASE_URL}/patient/member`,
    edit_member: (memberId) => `${API_BASE_URL}/patient/member/${memberId}`,
    get_member: `${API_BASE_URL}/patient/member`,
    delete_member: (memberId) => `${API_BASE_URL}/patient/member/${memberId}`,
  },
  MY_CONDITIONS: {
    get_conditions: `${API_BASE_URL}/patient/condition`,
    add_conditions: `${API_BASE_URL}/patient/condition`,
    delete_conditions: (conditionId) =>
      `${API_BASE_URL}/patient/condition/${conditionId}`,
  },
  SPEACIAL_NEEDS: {
    get_needs: `${API_BASE_URL}/patient/special-needs`,
    add_needs: `${API_BASE_URL}/patient/special-needs`,
    delete_needs: (needId) => `${API_BASE_URL}/patient/special-needs/${needId}`,
  },
  CAREGIVER: {
    caregiver_list: (
      filterLanguage,
      filterWorkerRole,
      filterGender,
      filterExperience,
      filterService,
      filterRating,
      specialNeeds,
      myConditions,
      city
    ) =>
      `${API_BASE_URL}/patient/caregiver?page=1&limit=100&language=${filterLanguage}&worker_role=${filterWorkerRole}&gender=${filterGender}&experience=${filterExperience}&services=${filterService}&average_rates=${filterRating}&highlight=${specialNeeds}&can_also_with=${myConditions}&city=${city}`,
    caregiver_details: (caregiver_id) =>
      `${API_BASE_URL}/patient/caregiver/${caregiver_id}`,
  },
  BOOK_APPOINTMENT: {
    book_appointment: (caregiver_id) =>
      `${API_BASE_URL}/patient/appointment/caregiver/${caregiver_id}`,
  },
  APPOINTMENT: {
    get_appointment: (page, limit) =>
      `${API_BASE_URL}/patient/appointment?page=${page}&limit=${limit}`,
    get_appointment_details: (bookingId) =>
      `${API_BASE_URL}/patient/appointment/${bookingId}`,
    get_appointmentByFilter: (page, limit, startDate, endDate) =>
      `${API_BASE_URL}/patient/appointment?page=${page}&limit=${limit}&start_appointment=${startDate}&end_appointment=${endDate}`,
  },
  GET_COUNTRY_STATE: {
    country: `https://countriesnow.space/api/v0.1/countries/flag/unicode`,
    state: `https://countriesnow.space/api/v0.1/countries/states`,
    city: `https://countriesnow.space/api/v0.1/countries/state/cities`,
  },
  FEEDBACK: {
    get_feedback: (caregiverId) =>
      `${API_BASE_URL}/patient/feedback/caregiver/${caregiverId}`,
    add_feedback: (userId) =>
      `${API_BASE_URL}/patient/feedback/caregiver/${userId}`,
  },
  PREFERENCES: {
    add_preference: `${API_BASE_URL}/patient/preference`,
  },
  FILTER: {
    filter: `${API_BASE_URL}/patient/filter`,
  },
  PAYMENT: {
    payment: `${API_BASE_URL}/payment/create-checkout-session`,
    get_my_payment: (page, limit) =>
      `${API_BASE_URL}/payment/my-payments?page=${page}&limit=${limit}`,
    get_PAYMENTByFilter: (startDate, endDate) =>
      `${API_BASE_URL}/payment/my-payments?page=1&limit=100&start_appointment=${startDate}&end_appointment=${endDate}`,
  },
  // USER_REGISTER_VIEW: {
  //   getview: `${API_BASE_URL}/api/v1/admin/userbyid`,
  // },
  SERVICE_NEED: {
    service_need: `${API_BASE_URL}/common/services-list`,
    update_service_need: `${API_BASE_URL}/common/services`,
    service_need_updated: `${API_BASE_URL}/common/services`,
  },
  BOOKING_SLOT: {
    booking_slot: (caregiver_id) =>
      `${API_BASE_URL}/patient/availability/caregiver/${caregiver_id}`,
    getDiscountedAmounts: (date, start_slots, end_slots) =>
      `${API_BASE_URL}/api/v1/patient/pricing/${date}/${start_slots}/${end_slots}`,
    checkSlotAvailability: (id) =>
      `${API_BASE_URL}/patient/check-unavailability/${id}`,
  },
  LANGUAGE: {
    get_language: `${API_BASE_URL}/common/language-speak`,
  },
  GET_MOBILE_APPLICATION: {
    mobile_app_data: `${API_BASE_URL}/mobile-application`,
  },
  PRIVACY_POLICY: {
    get_privacy_policy: `${API_BASE_URL}/common/policies`,
  },
  TERMS_AND_CONDITIONS: {
    get_terms: `${API_BASE_URL}/common/terms`,
  },
};
