import React, { useEffect, useState } from "react";
import { Grid, Box, Typography, Divider, Select, MenuItem, FormControl, Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { BlueButton, WhiteButton } from "../../common/button";
import DatepickerRange from "../../components/datepickerRange";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import moment from "moment";
import { axiosInstance } from "../../network/config/adapter";
import { ApiEndPoints } from "../../network/endpoints";
import DatepickerRangeFilter from "../../components/datepickerRangeFilter";
import toast from 'react-hot-toast'


import Translations from '../../@core/layouts/components/Translations';
import useCustomTranslation from '../../@core/hooks/useCustomTranslation';
// paymnetFilterDialog

export default function FilterPaymentDialog({ isOpen, toggle, filteredPaymentList }) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [resetTrigger, setResetTrigger] = useState(false);

    // Function to get star date and end date
    const getDates = (startDate, endDate) => {
        // console.log(startDate, endDate);

        setStartDate(() => {
            const formattedDate = moment(startDate).format('YYYY-MM-DD')
            return formattedDate;
        });
        setEndDate(() => {
            const formattedDate = moment(endDate).format('YYYY-MM-DD')
            return formattedDate;
        });
    }

    async function handleGetPayments() {
        try {
            const response = await axiosInstance.get(ApiEndPoints.PAYMENT.get_PAYMENTByFilter(startDate, endDate),);
            console.log("filter response", response.data.data);
            let newAppointmentList = response.data.data;
            filteredPaymentList(newAppointmentList);
            toggle();
        } catch (error) {

        }
    }

    function handleReset() {
        setStartDate("");
        setEndDate("");
        setResetTrigger(!resetTrigger);
    }

    return (

        <div>
            {
                isOpen && (
                    <Dialog
                        open={isOpen}
                        onClose={toggle}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogContent sx={{
                            paddingInline: 0,
                        }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingInline: "24px" }}>
                                <Typography fontSize="36px" fontWeight="bold">
                                    <Translations text="paymnetFilterDialog.label" />
                                </Typography>
                                <Button
                                    sx={{
                                        padding: "4px 8px",
                                        borderRadius: "40px",
                                        border: "1px solid #98A2B3",
                                        color: "#475467",
                                        textTransform: "none"
                                    }}
                                    onClick={() => handleReset()}
                                >
                                    <Translations text="paymnetFilterDialog.Clear filter" />
                                </Button>
                            </Box>
                            <Divider />
                            <Box sx={{ paddingInline: "24px", marginTop: "20px", }}>
                                <Box mb={2} sx={{ marginBottom: { xs: "40px", md: "0px" } }}>
                                    <Typography
                                        variant="body1"
                                        color="#667085"
                                        fontWeight={"bold"}
                                        marginBottom={"10px"}
                                    >
                                        <Translations text="paymnetFilterDialog.Date Range" />
                                    </Typography>
                                    <Box display={"flex"} gap={"10px"}>
                                        {/* <DatepickerRangeFilter  /> */}
                                        <DatepickerRangeFilter getDates={getDates} resetTrigger={resetTrigger} />

                                    </Box>
                                </Box>

                                <Divider sx={{ marginTop: "30px" }} />
                                <Grid container spacing={2} mt="15px">
                                    <Grid item xs={6} sm={6}>
                                        <WhiteButton onClick={toggle} fullWidth>
                                            Close
                                        </WhiteButton>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <BlueButton fullWidth onClick={handleGetPayments}>

                                            Apply
                                        </BlueButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        </DialogContent>
                    </Dialog>
                )
            }

        </div>
    )
}


